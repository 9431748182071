





































































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import Axios from "axios";
import {
  RedCapital,
  SelectedCountry,
  Configuration,
  TitleTemplateDashboard,
  Countries
} from "@/configuration";
import Currency from "@/components/Currency.vue";
import ReusableButton from "@/components/ReusableButton.vue";
import API from "@/api";
import DniField from "@/components/DniFieldBusiness.vue";
import { EventBus } from "@/main";
import { isRutValid } from "@/utils";

@Component({
  computed: {
    ...mapState(["user", "business"])
  },
  metaInfo: {
    title: "Solicitar Factoring",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
      canShow: true,
      btn: {
        uploadDTEs: {
          clickable: false,
          loading: false,
          isOk: null,
          successText: "Petición enviada",
          errorText: "Error al hacer la petición"
        }
      },
      valid: true,
      areInvoicesValid: false,
      dialog: false,
      errorMessage: null,
      businessData: null,
      businessName: null,
      businessSelected: "",
      block: [],
      rutInfo: null,
      rutInfo2: null,
      canSearch: [],
      errorRut: [],
      invoices: [
        {
          id: Date.now(),
          folio: 0,
          rut: "",
          amount: 0,
          name: "",
          term: 1
        }
      ],
      disableWarrantyInputs: true,
      rules: {
        notEmpty: [(v: any) => !!v || "Este campo es requerido"]
      }
    };
  },
  async beforeMount() {
    this.$data.businessData = await API.getBusiness();
    if (this.$store.state.business != null) {
      this.$data.businessSelected = this.$store.state.business;
      this.$data.canShow = true;
    } else {
      this.$data.businessSelected = "";
      this.$data.canShow = true;
    }
  },
  mounted() {
    EventBus.$on("blur-response", (payload: any) => {
      this.$data.invoices[payload.field].name = payload.name;
      // console.log(this.$data.invoices[payload.field])
    });
  },
  watch: {
    invoices: {
      handler(el: any) {
        // @ts-ignore
        this.validateInvoices();
      },
      deep: true
    }
  },
  methods: {
    fileUpdated(id: number) {
      let self = this;
      // @ts-ignore
      let file: File = this.$refs[`invoice_${id}`][0].files[0];
      let reader = new FileReader();
      reader.onload = function(ev) {
        // @ts-ignore
        let xmlString = ev.target.result;

        let parser = new DOMParser();
        // @ts-ignore
        var xmlDoc = parser.parseFromString(xmlString, "application/xml");

        self.$data.invoices.find((el: any) => {
          if (el.id == id) {
            el.folio = xmlDoc.getElementsByTagName(
              "Folio"
            )[0].childNodes[0].nodeValue;
            el.rut = xmlDoc.getElementsByTagName(
              "RUTRecep"
            )[0].childNodes[0].nodeValue;
            if (isRutValid(el.rut.toUpperCase()) && el.rut.length == 10) {
              Axios.get(`${Configuration.api.sii}${el.rut.toUpperCase()}`)
                .then((res: any) => {
                  EventBus.$emit("snack-success", "Rut válido");
                  const payload = {
                    dni: el.rut.toUpperCase(),
                    name: res.data.razon_social,
                    field: self.$data.invoices.indexOf(el)
                  };
                  EventBus.$emit("blur-response", payload);
                })
                .catch((error: any) => {
                  el.name = "";
                  EventBus.$emit(
                    "snack-error",
                    error.response.data.error.message
                  );
                });
            }
            el.amount = xmlDoc.getElementsByTagName(
              "MntTotal"
            )[0].childNodes[0].nodeValue;
            // el.name = xmlDoc.getElementsByTagName('RznSocRecep')[0].childNodes[0].nodeValue
            el.term = 30;
          }
        });
        // @ts-ignore
        self.validateInvoices();
      };

      reader.readAsText(file);
    },
    validateInvoices() {
      // TODO: Poner mas validaciones :D
      for (let index in this.$data.invoices) {
        if (
          this.$data.invoices[index].rut == "" ||
          this.$data.invoices[index].name == "" ||
          this.$data.invoices[index].amount == "" ||
          this.$data.invoices[index].term == ""
        ) {
          this.$data.btn.uploadDTEs.clickable = false;
          this.$data.areInvoicesValid = false;
          return;
        }
      }

      this.$data.btn.uploadDTEs.clickable = true;

      this.$data.areInvoicesValid = true;
    },
    uploadDTEs() {
      if (this.$data.areInvoicesValid) {
        this.$data.btn.uploadDTEs.loading = true;
        this.$data.errorMessage = null;

        let facturas = this.$data.invoices.map((invoice: any) => {
          return {
            folio: invoice.folio,
            rut: invoice.rut,
            monto: invoice.amount,
            nombre: invoice.name,
            plazo: invoice.term
          };
        });

        API.request
          .factoring(this.$data.businessSelected, facturas)
          .then(data => {
            this.$data.btn.uploadDTEs.isOk = true;
            setTimeout(() => {
              this.$router.push({
                path: "/panel/solicitante/solicitudes"
              });
            }, Configuration.Redirection.timeout);
          })
          .catch(error => {
            this.$data.errorMessage = "Error al subir las facturas";
            this.$data.btn.uploadDTEs.isOk = false;
          });
      } else {
        this.$data.errorMessage = "Tienes alguna factura invalida";
      }
    },
    clickUploadDTE(index: number) {
      // @ts-ignore
      this.$refs[`invoice_${index}`][0].click();
    },
    addInvoice() {
      if (this.$data.invoices.length <= 10) {
        this.$data.invoices.push({
          id: Date.now(),
          folio: 0,
          rut: "",
          amount: 1,
          name: "",
          term: 0
        });
      } else {
        EventBus.$emit(
          "snack-success",
          "Si quieres subir mas de 10 facturas contactanos: contacto@redcapital.cl"
        );
      }
    },
    removeInvoice(index: number) {
      if (this.$data.invoices.length > 1) {
        this.$data.invoices = this.$data.invoices.filter(
          (el: any, i: number) => {
            if (index != i) {
              return true;
            } else {
              return false;
            }
          }
        );
      }
    },
    check(index: number) {
      console.log(index);
    }
  },
  components: {
    Currency,
    ReusableButton,
    DniField
  }
})
export default class Factoring extends Vue {}
